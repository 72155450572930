/*
CSS for maps component
Author: Cyril Fatis
company: cdizign


*/


/*
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
                CARD ON MAP
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #ff7f50;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.infowindow {
    min-width: 500px;
}

.maps-card-image-old {
    max-width: 115px !important;
    position: fixed;
    top: -70px;
    right: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.maps-card-image {
    position: absolute;
    top: -50px;
    left: -75px;
    height: 30vh;
    min-width: 10vw;
    float: left;
    margin: 3px 12px 3px 0;
    border: 1px solid #000000;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 6px;
    rotate: -5deg;
    z-index: 999;
}
/** Icon styling */
.sp-button {
    width: 28px;
    color: #435244;
    box-shadow: 5px 15px 5px #000000;
}

.sp-button-active {
    width: 28px;
    color: #4caf50;
    box-shadow: 5px 15px 5px #000000;
}

.sp-button-add-place {
    width: 28px;
    left: -30px;
    /*box-shadow: 5px 15px 5px rgba(0, 0, 0, 0.52);*/
}
.sp-icon-add-place {
    width: 28px;
    color: #4caf50 !important;
    /*box-shadow: 5px 15px 5px rgba(0, 0, 0, 0.52);*/
}

/** Side Panel */
.divider-side-panel {
    margin-top: 30px !important;
    margin-bottom: 10px !important;
    background-color: rgba(46,53,65,0.5) !important;
    height: 2px !important;
}

.side-panel-business-type-container {
    display: flex !important;
    flex-direction: row !important;
    padding: 10px !important;
    margin-top: 25px !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
}
/*
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
Override of existing component style
++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */
.gm-style .gm-style-iw-c {
    overflow: visible !important;
}
.autoComplete-div {
    width: 100%;
}
.maps-card {
    /*min-width: 30rem !important;*/
    width: 40vw;
    max-height: 40vh;
    height: 33vh;
    overflow: hidden;
    background-color: rgba(34, 36, 44);
    padding: 5px;

}

.maps-card-title {
    font-weight: 600;
    font-size: 24px;
    color: #ff7f50;
    margin-bottom: 0.25rem;
    position: relative;
    z-index: 999;
    text-align: center;
}


.maps-card-subtitle {
    font-weight: 500;
    font-size: 0.9rem;
    font-style: normal;
    color: #ffffff;
    text-align: center;
    margin-bottom: unset !important;
}

.maps-card-tarif {
    font-weight: 400;
    font-size: 0.85rem;
    font-style: normal;
    color: #ffffff;
    text-align: center;
}

.distance-top {
    font-weight: 400;
    font-size: 0.8rem;
    font-style: normal;
    color: #ffffff;
    position: absolute;
    right: 10px;/*30px;*/
    top: -50px;/*30px;*/
    border: 2px solid #ff7f50;
    border-radius: 50%;
    padding: 10px;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    text-align: center;
    overflow: hidden;
}

.maps-card-text {
    text-align: center;
    color: #aab5ba;
    font-size: 0.95rem;
    max-width: 100%;
    max-height: 16vh;
    margin-top: 15px;
    padding-left: 120px;
    padding-right: 120px;
    overflow: auto;
}

.card-button-expand {
    font-size: 0.9rem;
    color: #aab5ba !important;
    background-color: unset;
    border: none;
    display: unset;
    font-weight: normal !important;
    padding: 0 !important;
    border-radius: unset;
    text-align: unset;
    vertical-align: unset;
    line-height: unset;
    transition: all ease-in-out 0.5s;
}

.card-button-expand:hover {
    background-color: unset !important;
    border-color: unset !important;
    font-weight: bold !important;
    color: #ffffff !important;
    transition: all ease-in-out 0.6s;

}

.card-btn-details {
    background-color: #3d424a91 !important;
    color: #ffffff !important;
    border: none !important;
    padding: 4px;
    transition: all ease-in-out 0.5s;
    width: 90% !important;
    margin: 15px;
    /*position: absolute;*/
    /*bottom: 15px;*/
}

.card-btn-details:hover {
    text-decoration: none;
    background-color: transparent !important;
    color: #ff7f50 !important;
    transition: all ease-in-out 1s;
}

.card-btn-details-partner {
    background-color: #3d424a91 !important;
    color: #ffffff !important;
    border: none !important;
    padding: 4px;
    transition: all ease-in-out 0.5s;
    margin-top: 30px;
    width: 100%;
}
.card-btn-details-partner:hover {
    text-decoration: none;
    background-color: transparent !important;
    color: #ff7f50 !important;
    transition: all ease-in-out 1s;
}

/*            MEDIA QUERIES                 */
@media (max-width: 480px) {
    .maps-card {
        /*min-width: 30rem !important;*/
        width: unset;
        overflow: hidden;
        max-height: 70vh;
        height: fit-content;
        padding-bottom: 40px;
    }
    .maps-card-title {
        font-size: 14px;
    }
    .maps-card-image {
        height: 16vh;
        top: -50px;
    }
    .maps-card-text {
        max-height: 60vh;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
    }
    .distance-top {
        position: inherit;
        animation-delay: 0ms;
        text-align: center;
        align-items: center;
        align-content: space-around;
        /* right: 50%; */
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;

    }
    .distance-top-container {
        position: inherit;
        text-align: center;
        animation-delay: 0ms;
        align-self: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: space-around;
        justify-content: center;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .side-panel-container {
        width: 95vw !important;
    }
    .sp-button {
        flex-basis: calc(33.33% - 30px) !important;
        box-shadow: unset !important;
    }
    .side-panel-business-type-container {
        display: flex !important;
        margin-top: 25px !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: space-around !important;
        height: 175px !important;
    }
}
