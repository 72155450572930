body {
    background: rgb(36 40 43) !important;
}


.moveon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.moveon-store-container {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.moveon-ul-container {
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    justify-content: space-evenly;
}

.moveon-title {
    color: #ffffff;
    width: 60vw;
    text-align: center;
}

.moveon-app-name {
    max-height: 300px;
}


/*
###################################################
- Responsive design
###################################################
*/
@media all and (min-width: 1024px) and (max-width: 1280px) {
    .moveon-title {
        color: #ffffff;
        width: 75vw;
        text-align: center;
    }
}

@media all and (min-width: 768px) and (max-width: 1024px) {
    .moveon-title {
        font-size: 1.5em;
        color: #ffffff;
        width: 75vw;
        text-align: center;
    }
}

@media all and (min-width: 480px) and (max-width: 768px) {
    .moveon-title {
        font-size: 1.5em;
        color: #ffffff;
        width: 70vw;
        text-align: center;
    }
}

@media all and (max-width: 480px) {
    .moveon-title {
        font-size: 1.5em;
        color: #ffffff;
        width: 80vw;
        text-align: center;
    }
}
