.form-group{
    position: relative;
    margin-bottom: 1.5rem;
}

.form-control-placeholder {
    position: absolute;
    top: 0;
    padding: 7px 0 0 13px;
    transition: all 200ms;
    opacity: 0.5;
}

.form-control:focus + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
    font-size: 75%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
}

.sp-btn{
    background-color: #3f51b5;
    border-color: #3f51b5;
}





