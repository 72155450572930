/*********************************************************************/
/* Ovverirde */
/*********************************************************************/

.MuiPaper-elevation1 {
    height: 100%;
    background-color: transparent !important;
    box-shadow: none !important;
}

.MuiTypography-colorTextSecondary {
    color: #dedcdc !important;
}

.MuiCardHeader-title {
    font-size: 1.3rem !important;
}

.MuiCardHeader-subheader {
    font-size: 0.75rem !important;
}

.MuiIconButton-label {
    color: #db6334 !important;
}

.MuiCardActions-root {
    position: absolute;
    /*bottom: 0;*/
}

.MuiCardContent-root {
    color: #dedcdc !important;
    padding: 0 16px 0 16px;
}
.MuiCardContent-root:last-child {
    padding-bottom: 0 !important;
}

.expanded-cardContent {
    margin-top: 60px;
}
.MuiIconButton-root {
    padding: 0 !important;
}

/* UI Dialogue */
.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: #28bae6;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
}

/*********************************************************************/
/* Détails CSS */
/*********************************************************************/

.sp-background {
    position: absolute;
    filter: blur(20px) brightness(35%);
    -webkit-filter: blur(20px) brightness(35%);
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.sp-div-card {
    width: 50%;
    background-color: #0f1010b8;
    position: absolute;
    margin-top: 10px;
    /*top: 50%;*/
    left: 50%;
    transform: translate(-50%, 2%);
}

.sp-rotate-detail-icon {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    font-size: 2rem !important;
    margin-top: 15px !important;
}

.sp-share {
    margin: 0 12px;
}

.background-card {
    background-color: transparent !important;
}

.title {
    color: #db6334 !important;
}

.dis-button {
    color: rgba(107, 108, 110, 0.78) !important;
}

.en-button {
    margin-top: 2px;
}

.book-container {
    max-width: unset !important;
}

.book {
    background-color: #db6334 !important;
    width: auto !important;
    color: #c1f5f5 !important;
    border-radius: 3px;
    padding: 4px;
    transition: all ease-in-out 0.5s;
    margin-bottom: 15px;
}

.book:hover {
    text-decoration: none;
    background-color: #ff5200 !important;
    color: #ffffff !important;
    transition: all ease-in-out 1s;
}

.spacer-bt {
    margin-bottom: 25px !important;
}


/*********************************************************************/
/*                              COMPANY CONTAINER                    */
/*********************************************************************/
.company-container {
    background-color: rgba(117,117,117, 0.17);
    margin-bottom: 3rem;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
}

.company-title {
    color: #bdbcbc !important;
    text-align: right;
}

.company-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.company-btn-call {
    font-size: 0.9em;
    color: #db6334 !important;
    transition: all ease-in-out 1s;
    /*-o-transition: color 1s ease-in-out; !* opera *!*/
    /*-moz-transition: color 1s ease-in-out; !* Firefox *!*/
    /*-webkit-transition: color 1s ease-in-out; !*safari and chrome *!*/
}

.company-btn-call:hover {
    font-size: 1em;
    color: #f18249 !important;
    text-decoration: none !important;
    transition: all ease-in-out 0.5s;
    /*-o-transition: color 1s ease-in-out; !* opera *!*/
    /*-moz-transition: color 1s ease-in-out; !* Firefox *!*/
    /*-webkit-transition: color 1s ease-in-out; !*safari and chrome *!*/
}

.company-address {
    text-align: right;
    font-style: italic;
}
/*********************************************************************/
/*                              Sharing CONTAINER                    */
/*********************************************************************/
.sharing-container {
    width: 8000px;
    background-color: transparent !important;
    margin-top: 25px;
    margin-bottom: 25px;
}

/*********************************************************************/
/*                              MAP CONTAINER                        */
/*********************************************************************/
.map-container {
    background-color: transparent !important;
    margin-top: 25px;
    margin-bottom: 25px;
}


/*********************************************************************/
/*                              MEDIA QUERIES                        */
/*********************************************************************/
@media (max-width: 480px) {
    .sp-div-card {
        width: 100%;
        background-color: #0f1010b8;
        position: absolute;
        margin-top: unset;
        top: unset;
        left: unset;
        transform: none;
    }
    .MuiCardActions-root {
        position: relative;
        bottom: unset;
    }
}
