.confirm-intro {
    color: #dedcdc;
    text-align: center;
}

.confirm .MuiButton-containedPrimary:hover {
    background-color: #0069d9 !important;
    color: #dedcdc !important;
    transition: all ease-in-out 500ms;
}
